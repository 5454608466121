import request from '../http'
import {managerUrl} from '../api'

//收货地址列表接口
export function addressList(data) {
    return request({
        url: managerUrl + "/carton/shop/address/list",
        method: 'post',
        data
    })
}

//新增收货地址接口
export function addAddress(data) {
    return request({
        url: managerUrl + "/carton/shop/address/add",
        method: 'post',
        data
    })
}

//编辑收货地址接口
export function editAddress(data) {
    return request({
        url: managerUrl + "/carton/shop/address/edit",
        method: 'post',
        data
    })
}

//删除地址接口
export function delAddress(data) {
    return request({
        url: managerUrl + "/carton/shop/address/del",
        method: 'post',
        data
    })
}