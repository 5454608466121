<template>
  <el-dialog
      v-model="dialogVisible"
      :title="title"
      width="520"
      modal-class="shop-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeDia"
      @open="openDia"
  >
    <el-form
        label-width="100"
        :rules="rules"
        ref="formRef"
        :model="formData"
        class="chang-pwd-form "
    >
      <el-form-item
          prop="name"
          label="联系人"
      >
        <el-input
        v-elInput
            v-model="formData.name"
            type="text"
            class=" large-input"
            maxlength="30"
            placeholder="请输入联系人名称"
        />
      </el-form-item>
      <el-form-item
          prop="mobile"
          label="联系人电话"
      >
        <el-input
        v-elInput
            v-model="formData.mobile"
            type="number"
            maxlength="11"
            class="no-number large-input"
            placeholder="请输入联系人电话"
        />
      </el-form-item>
      <el-form-item
          prop="name"
          label="收货地址"
      >
        <el-input
        v-elInput
            v-model="formData.address"
            type="text"
            class=" large-input"
            maxlength="100"
            placeholder="请输入收货地址"
        />
      </el-form-item>
      <el-form-item
      >
        <div class="btn-box">
          <button type="button" class="wy-button info mr_16" @click="dialogVisible = false">取消</button>
          <button type="button" class="wy-button primary" @click="submitForm(formRef)">确认</button>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script setup>
import {getCurrentInstance, toRefs, ref, reactive, computed} from "vue";
import {formRules} from '../../../config.js'
import {addAddress, editAddress} from '@utils/api/address'

const formRef = ref(null);
const props = defineProps({
  addressInfo: {
    type: Object,
    default: () => {
    },
  },
})
const {addressInfo} = toRefs(props)
const formData = reactive({
  //联系人名称
  name: '',
  //联系人电话
  mobile: '',
  //联系人地址
  address: '',
  id: '',
})

const rules = reactive({
  name: formRules.nickName,
  mobile: formRules.phone,
  address: formRules.address,
});
const title = computed(() => {
  return addressInfo.value?.id ? '修改地址' : '新增地址'
})
const dialogVisible = ref(false)
const {proxy} = getCurrentInstance();
const openDia = () => {
  if (addressInfo.value?.id) {
    formData.name = addressInfo.value.name
    formData.mobile = addressInfo.value.mobile
    formData.address = addressInfo.value.address
    formData.id = addressInfo.value.id
  } else {
    formData.name = ''
    formData.mobile = ''
    formData.address = ''
    formData.id = ''
  }
}
const closeDia = () => {
  dialogVisible.value = false
  formRef.value.clearValidate()
}
const submitForm = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      const api = addressInfo.value?.id ? editAddress : addAddress
      const params = {...formData}
      api(params).then(res => {
        if (res.code !== 0) {
          proxy.$message.error(res.msg)
          return
        }
        proxy.$message.success('操作成功')
        dialogVisible.value = false
        proxy.$emit('update')
      })
    } else {
      return false;
    }
  });
}
defineExpose({
  dialogVisible,
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "addAddress",
});
</script>
<style lang="scss" scoped>
  .chang-pwd-form{
    :deep(.el-input){
      font-size: 14px;
      &.large-input{
        font-size: 14px;
      }
    }
    .wy-button{
      &.info{
        font-weight: normal;
      }
    }
  }
</style>